import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import "../project-container/project-container.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "./../network/api";
import { baseUrl } from "../network/constants/Constants";

const AllProjects = () => {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [project, setProjects] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProject();
  }, []);
  

  const fetchProject = () => {
    setLoading(true);
  
    axios
      .get(baseUrl + "web/projects/")
      .then((response) => {
        console.log("API response data:", response.data);
        setLoading(false);

       if (response.data && Array.isArray(response.data)) {
            setProjects(response.data);
        } else {
            console.error("Unexpected data format:", response.data);
            setProjects([]);
        }

      })
      .catch((error) => {
        setLoading(false); 
        console.error("Error fetching blog data:", error);
      });
  };

  // Handle navigation to the project page
  const handleProjectNavigation = (id) => {
    const projectItem = project.find((item) => item.id === id);
    navigate(`/projectpage/${id}`, { state: projectItem });
  };

  return (
    <section data-section>
      <Container style={{ marginTop: 91 }}>
        {project.length > 0 ? (
          <div className="projects-list">
            {project.map((projectItem) => (
              <div
                className="project-card"
                key={projectItem.id}
                onClick={() => handleProjectNavigation(projectItem.id)}
              >
                <div className="project-item-background">
                  <div className="prj-img-container">
                    <img src={projectItem.thumb} alt={projectItem.name} />
                  </div>
                  <div className="project-details">
                    <span>{projectItem.project_name}</span>
                    <p className="support-text"  dangerouslySetInnerHTML={{
                        __html:
                          projectItem.description.length > 250
                            ? projectItem.description.slice(0, 70) + "..."
                            :projectItem.description,
                      }}/>
                    <Link
                      onClick={() => handleProjectNavigation(projectItem.id)}
                      style={{ textDecoration: "none" }}
                    >
                      MORE
                    </Link>
                  </div>
                  <div className="green-box"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No projects to display.</p>
        )}
      </Container>
    </section>
  );
};

export default AllProjects;
