import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./services.css";

function ServiceComponent() {
  return (
    <section id="services" data-section>
      <Container>
        {/* <h2 className="service-heading">Our services</h2> */}
        <Row>
          <Col className="d-flex justify-content-center  services ">
            <div className="support-elements">
              <div className="support-card ">
                <div>Planing & Construction</div>
                <p className="support-text">
                  Ekohomez covers all aspects of construction with maintaining
                  high standard and ensure a world class finish to our entire
                  construction project. Ekohomez undertake civil construction
                  works for residential and commercial projects. Our
                  professional team with many years of experience ensure your
                  civil construction project is delivered safely, efficiently
                  and profitably.
                </p>
              </div>
              <div className="support-card ">
                <div>Interior & Exterior Design</div>
                <p className="support-text">
                  Ekohomez offers Interior /Exterior design service for both new
                  construction and remodel project. Believing that the best
                  design are the result of a collaborative approach. Our design
                  team take project from conception through to completion often
                  providing project management service for clients who are
                  planning to build or remodelling.
                </p>
              </div>
              <div className="support-card ">
                <div>Renovations</div>
                <p className="support-text">
                  We support you to a great way to make your realize your dream
                  house without investing a lot of money. it’s also a great way
                  to add values to your house if you plan to sell it in feature.
                  We can help you to renovating more satisfying and pleasant
                  house to live and it will help enhance your home value.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ServiceComponent;
