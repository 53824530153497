import "./App.css";
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Navbar from "./components/navbar/navbar";
import FooterComponent from "./components/footer/footer";
import Home from "./Pages/home";
import ProjectPage from "./Pages/project_page";
import OneProjectPage from "./Pages/one_project_page";
import ContactComponent from "./components/contact/contact";


function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contactus' element={<ContactComponent />} />
        <Route path='/projectPage' element={<ProjectPage/>} />
        <Route path='/projectPage/:Id' element={<OneProjectPage/>} />
      </Routes>
      <FooterComponent /> 
      </BrowserRouter>
    </div>
  );
}

export default App;
