import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player/youtube";
import PhoneIcon from "./../../assets/ring.png";
import axios from "../network/api";
import { baseUrl } from "../network/constants/Constants";
import "./social-media.css";

const SocialMediaComponent = () => {
  const [youtubeGallery, setYoutubeGallery] = useState([]);
  const [youtubegalleryLoading, setYoutubeGalleryLoading] = useState(true);

  useEffect(() => {
    fetchYoutubeGallery();
}, []);

  const fetchYoutubeGallery = () => {
    setYoutubeGalleryLoading(true);
    axios
     .get(baseUrl + "web/video-gallery/")
     .then((response) => {
      setYoutubeGalleryLoading(false);
      setYoutubeGallery(response.data);
  
     if (response.data && Array.isArray(response.data)) {
      setYoutubeGallery(response.data);
  } else {
      console.error("Unexpected data format:", response.data);
      setYoutubeGallery([]);
  }
  
})
     .catch((error) => {
      console.log("Error" + error);
      setYoutubeGalleryLoading(false);
  });
    
  };

  return (
    <section>
      <Container fluid className="socialmedia-container">
        <Container>
          <Row>
            {youtubeGallery &&
              youtubeGallery.map((item, index) => {
                return (
                  <Col md={6} className="youtube" key={index} >
                    <ReactPlayer
                      // url="https://www.youtube.com/watch?v=XFvh53hqaIA"
                      url={item.youtube_video_link}
                      className="reactplayer"
                      playing={false}
                      controls={false}
                      width={530}
                      height={260}
                    />
                  </Col>
                );
              })}

            <Col md={6} className="content">
              <div className="social-media-all-content">
                <div className="social-media-content">
                  <span>What We Do</span>
                  <h1>WE ARE TECHNICALLY PASSIONATE</h1>
                  <p>
                    We follow the standard technical specifications throughout
                    and construct our projects with meticulous care. We ensure
                    the best materials in all aspects of construction and follow
                    eco-friendly building practices
                  </p>
                </div>
                <div className="social-media-footer">
                  <button>
                    <a
                      style={{ textDecoration: "none", color: "white" }}
                      href="https://www.youtube.com/@ekohomezbuilders7989"
                    >
                      EXPLORE NOW
                    </a>
                  </button>
                  <div className="contact">
                    <img src={PhoneIcon} alt="Phone Icon" className="phone" />
                    <a
                      href="tel:+91 95445 06205"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="contact-content">
                        <h6>Call Anytime</h6>
                        <h5>+91 95445 06205</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
};

export default SocialMediaComponent;
