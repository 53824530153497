

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

import "./gallery-modal.css";

const GalleryModal = (props) => {
  const [currentIndex, setCurrentIndex] = useState(props.initialIndex);

  useEffect(() => {
    setCurrentIndex(props.initialIndex);
  }, [props.initialIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? props.galleryImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === props.galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleCloseModal}
      size="lg"
      className="transparent-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-image-container">
          <Button variant="light" onClick={handlePrevious} className="arrow-btn">
            <FaArrowLeft />
          </Button>
          <img
            src={props.galleryImages[currentIndex]?.file}
            alt="Gallery"
            className="modal-image"
          />
          <Button variant="light" onClick={handleNext} className="arrow-btn">
            <FaArrowRight />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GalleryModal;






// import React, { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
// import Modal from "react-bootstrap/Modal";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

// import "./gallery-modal.css";

// const GalleryModal = (props) => {
//   const [currentIndex, setCurrentIndex] = useState(props.initialIndex);

//   useEffect(() => {
//     setCurrentIndex(props.initialIndex);
//   }, [props.initialIndex]);

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? props.galleryImages.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === props.galleryImages.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   return (
//     <Modal
//       show={props.show}
//       onHide={props.handleCloseModal}
//       size="lg"
//       className="transparent-modal"
//     >
//       <Modal.Header closeButton></Modal.Header>
//       <Modal.Body>
//         <div className="modal-image-container">
//           <Button variant="light" onClick={handlePrevious} className="arrow-btn">
//             <FaArrowLeft />
//           </Button>
//           <img
//             src={props.galleryImages[currentIndex]?.image}
//             alt="Gallery"
//             className="modal-image img-fluid"
//           />
//           <Button variant="light" onClick={handleNext} className="arrow-btn">
//             <FaArrowRight />
//           </Button>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default GalleryModal;
