import React,{useEffect} from "react";
import { Container, Image } from "react-bootstrap";
import "./each_project.css";
import { useLocation } from "react-router-dom";

const EachProjects = () => {
  
  const location = useLocation();
  const projectData = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (!projectData) {
    return <div>Project not found.</div>;
  }

  return (
    <section>
      <div className="project-img-container">
      <img alt="projectimage" src={projectData.banner}  className="center-img" />
      </div>
      <Container>
        <div className="facilities">
          <span className="project-facilities"   
          dangerouslySetInnerHTML={{
              __html: projectData.features
            }}/>
        </div>
        <div className="building-planinng">
          <h5>{projectData.project_name}</h5>
          <p>{projectData.description}</p>
        </div>
      </Container>
    </section>
  );
};

export default EachProjects;
