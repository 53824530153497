import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Symbol from "./../../assets/Symbol.png";
import "./testimonials.css";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import axios from "axios";
import { baseUrl } from "../network/constants/Constants";
import { Parser } from "html-to-react";



const Testimonials = () => {

  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [maxVisibleSlide, setMaxVisibleSlide] = useState(3);
  const [currentVisibleSlide, setCurrentVisibleSlide] = useState(3);

  const fetchProject = () => {
    setLoading(true);
    axios
      .get(baseUrl + "web/testimonials/")
      .then((response) => {
        setLoading(false);
        if (response.data && Array.isArray(response.data)) {
          setTestimonials(response.data);
        } else {
          console.error("Unexpected data format:", response.data);
          setTestimonials([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching testimonials data:", error);
      });
  };

  useEffect(() => {
    fetchProject();
  }, []); 

  return (
    <section id="testimonials">
      {testimonials.length > 0 && ( 
        <Container fluid>
          <Row className="testimonials-container">
            <Col md={5}>
              <h1 className="testimonials-heading-one">Testimonials</h1>
              <h1 className="testimonials-heading-two">
                See What Our Clients<br></br> Say
              </h1>
              <div className="left-right-btn">
                <div
                  className="left-btn"
                  onClick={() => {
                    ref.current?.goNext();
                  }}
                >
                  <FaArrowLeft />
                </div>
                <div
                  className="right-btn"
                  onClick={() => {
                    ref.current?.goBack();
                  }}
                >
                  <FaArrowRight />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <ResponsiveContainer
                carouselRef={ref}
                render={(parentWidth, carouselRef) => {
                  let newMaxVisibleSlide = maxVisibleSlide;
                  let newCurrentVisibleSlide = currentVisibleSlide;

                  if (parentWidth <= 1080) {
                    newMaxVisibleSlide = 1;
                    newCurrentVisibleSlide = 1;
                  } else if (parentWidth <= 1440) {
                    newMaxVisibleSlide = Math.min(
                      testimonials.length, 
                      3
                    );
                    newCurrentVisibleSlide = Math.min(
                      currentVisibleSlide,
                      newMaxVisibleSlide
                    );
                  }

                  if (newMaxVisibleSlide % 2 === 0) {
                    newMaxVisibleSlide -= 1;
                    newCurrentVisibleSlide -= 1;
                  }

                  return (
                    <StackedCarousel
                      id="new-class"
                      ref={carouselRef}
                      slideComponent={TestimonialsItem}
                      slideWidth={parentWidth < 800 ? parentWidth - 40 : 900}
                      carouselWidth={parentWidth}
                      data={testimonials} 
                      currentVisibleSlide={newCurrentVisibleSlide}
                      maxVisibleSlide={newMaxVisibleSlide}
                      useGrabCursor
                      className={`stacked-carousel-container`}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

const TestimonialsItem = React.memo(function (props) {
  const { data, dataIndex } = props;
  const testimonial = data[dataIndex];
  const htmlParser = new Parser(); 
  return (
    <div className={`testimonial-item `}>
      <div className="testimonial-img-name">
        <div className="img-div">
          <div className="img-front">
            <img width={18} height={18} src={Symbol} alt="" />
          </div>
          <img className="test-img" src={testimonial.image} alt="person"/>
          <div className="img-back"></div>
        </div>
        <div className="testimonial-name-place">
          <h5>{testimonial.name}</h5>
          <span>{testimonial.place}</span>
        </div>
      </div>
      <div className="testimonial-details">
      
        {htmlParser.parse(testimonial.description)}
       
        {/* <p className="testimonials-para" dangerouslySetInnerHTML={{
              __html: testimonial.description
            }}/>
       */}
      </div>
    </div>
  );
});

export default Testimonials;








// import React, { useRef } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
// import Symbol from "./../../assets/Symbol.png";
// import "./testimonials.css";
// import {
//   StackedCarousel,
//   ResponsiveContainer,
// } from "react-stacked-center-carousel";

// import testimonialsDetails from "./dummy-testimonial";

// const Testimonials = () => {
//   const ref = useRef(null);

//   const maxVisibleSlide = 3;
//   const currentVisibleSlide = 3;

//   return (
//     <section id="testimonials">
//       <Container fluid>
//         <Row className="testimonials-container">
//           <Col md={5}>
//             <h1 className="testimonials-heading-one">Testimonials</h1>
//             <h1 className="testimonials-heading-two">See What Our Clients<br></br> Say</h1>
//             <div className="left-right-btn">
//               <div
//                 className="left-btn"
//                 onClick={() => {
//                   ref.current?.goNext();
//                 }}
//               >
//                 <FaArrowLeft />
//               </div>
//               <div
//                 className="right-btn"
//                 onClick={() => {
//                   ref.current?.goBack();
//                 }}
//               >
//                 <FaArrowRight />
//               </div>
//             </div>
//           </Col>
//           <Col md={7}>
//             <ResponsiveContainer
//               carouselRef={ref}
//               render={(parentWidth) => {
//                 return (
//                   <StackedCarousel
//                     ref={ref}
//                     slideComponent={TestimonialsItem}
//                     slideWidth={parentWidth < 800 ? parentWidth - 40 : 900}
//                     carouselWidth={parentWidth}
//                     data={testimonialsDetails}
//                     currentVisibleSlide={currentVisibleSlide}
//                     maxVisibleSlide={maxVisibleSlide}
//                     useGrabCursor
//                     className="stacked-carousel-container"
//                   />
//                 );
//               }}
//             />
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// const getSlideClass = (dataIndex) => {
//   if (dataIndex === 1) {
//     return 'second-slide';
//   } else if (dataIndex === 2) {
//     return 'third-slide';
//   }else if (dataIndex === 3) {
//     return 'first-slide';
//   }else {
//     return '';
//   }
// };

// const TestimonialsItem = React.memo(function (props) {
//   const { data, dataIndex } = props;
//   const testimonial = data[dataIndex];
//   const slideClass = getSlideClass(dataIndex);

//   return (
//     <div className={`testimonial-item ${slideClass}`}>
//       <div className="testimonial-img-name">
//         <div className="img-div">
//           <div className="img-front">
//             <img width={18} height={18} src={Symbol} alt="" />
//           </div>
//           <div className="test-img"></div>
//           <div className="img-back"></div>
//         </div>
//         <div className="testimonial-name-place">
//           <h5>{testimonial.name}</h5>
//           <span>{testimonial.place}</span>
//         </div>
//       </div>
//       <div className="testimonial-details">
//         <p>{testimonial.details}</p>
//       </div>
//     </div>
//   );
// });

// export default Testimonials;




  // const fetchTestimonials = () => {
  //   const numTestimonials = testimonialsDetails.length;
  //   let newMaxVisibleSlide = Math.min(3, Math.ceil(numTestimonials / 2) + 1);
  //   newMaxVisibleSlide =
  //     newMaxVisibleSlide % 2 === 0
  //       ? newMaxVisibleSlide - 1
  //       : newMaxVisibleSlide;
  //   setMaxVisibleSlide(newMaxVisibleSlide);

  //   let newCurrentVisibleSlide = Math.min(
  //     currentVisibleSlide,
  //     newMaxVisibleSlide
  //   );
  //   if (newMaxVisibleSlide === 1) {
  //     newCurrentVisibleSlide = 1;
  //   }

  //   setCurrentVisibleSlide(newCurrentVisibleSlide);
  // };





