import React from 'react';
import './view_more_banner.css';
import { Container, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';

const ViewMoreBanner = () => {
  const navigate = useNavigate();

  const handleMoreProjectsClick = () => {
    navigate('/projectPage', { state: { scrollTo: '#projects' } });
  }
  return (
    <section id='view-more'>
    <div className='view-more-project-banner'>
        <Container>
            <Row>
                <Col  className='view-more-project-p' md={8}>
                <p>You Dream it We are make it</p>
                </Col>
                <Col md={4} className='view-more-project-btn'  >
                <button  onClick={handleMoreProjectsClick}  className='project-btn'>VIEW MORE PROJECTS</button>
                </Col>
            </Row>
      
        </Container>
        </div>
</section>
  )
}
export default ViewMoreBanner;
