import React from 'react';
import './book_banner.css';
import { Container, Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router';
// import { HashLink } from 'react-router-hash-link';
// import { Link } from "react-router-dom";

const BookBanner = () => {
  
  const navigate = useNavigate();

  const handleContactUsClick = () => {

    navigate('/contactus',{ state: { scrollTo: '#contactus' } });
  }
    
  return (
    <section>
    <div className='book-banner'>
        <Container>
            <Row>
                <Col md={8} className='book-banner-container'>
                <p>Get in touch with our experts!</p>
                <h1>Book a Free Consultation</h1>
                </Col>
                <Col md={4}  className='book' >
                <button   onClick={handleContactUsClick}  className='book-banner-btn'>CONTACT US</button>
                {/* <HashLink smooth to="/#contactus"><button className='book-banner-btn'>CONTACT US</button></HashLink> */}
                {/* <Link to="/#contactus"> <button className='book-banner-btn'>CONTACT US</button></Link> */}
                </Col>
            </Row>
      
        </Container>
        </div>
</section>
  )
}
export default BookBanner;
