import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import { baseUrl } from "../network/constants/Constants";
import Alert from "@mui/material/Alert";
import ContactImage from "./../../assets/contact-img.svg";
import TextField from "@mui/material/TextField";
import "./contact.css";
import { useLocation } from "react-router-dom";

const ContactComponent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const location = useLocation();

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (location.state && location.state.scrollTo) {
  //     const targetElement = document.querySelector(location.state.scrollTo);
  //     if (targetElement) {
  //       targetElement.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }
  // }, [location]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    trigger,
  } = useForm();

  function handleSubmitMessage(e) {
    submitMail();
    setName("");
    setPhone("");
    setEmail("");
    setAddress("");
    setMessage("");
    reset();
  }

  const submitMail = () => {
    setLoading(true);
    const payload = {
      name: name,
      email: email,
      phone_number: phone,
      address: address,
      message: message,
    };

    console.log("payload: " + JSON.stringify(payload));
    axios
      .post(baseUrl + "web/book-consultation/create/", payload)
      .then((response) => {
        console.log(response.payload);
        console.log(response.data);
        setSuccess(true);

      })
      .catch((error) => {
        setLoading(false);
        console.log(error.data);
      });
  };

  const clearInputError = (fieldName) => {
    setError(fieldName, {
      type: "manual",
      message: "",
    });
  };

  return (
    <section id="contactus" data-section>
      <Container className="contact-main-container">
        <Row>
          <Col lg={5} className="contact-img-container">
            <Image src={ContactImage} />
            <div className="text-center contact-p">
              <span>
                For further inquiries, please don't hesitate to get in touch
                with us.
              </span>
            </div>
          </Col>

          <Col lg={7} className="contact-container">
            <div className="contact-head">
              <span>Get In Touch With Our Experts!</span>
              <h1>Book a Free Consultation</h1>
            </div>
            <div>
              <form
                className="contact-form"
                onSubmit={handleSubmit(handleSubmitMessage)}
              >
                <div className="text-field-first">
                  <div className="text-field-error">
                    <TextField
                      name="name"
                      id="name"
                      type="text"
                      label="Your Name *"
                      placeholder="Your Name *"
                      autoComplete="off"
                      className={`contact-form-textfield ${
                        errors.name && "invalid"
                      }`}
                      {...register("name", {
                        required: "Name is Required !",
                      })}
                      onChange={(e) => {
                        clearInputError("name");
                        setName(e.target.value);
                      }}
                    />
                    {errors.name && (
                      <small className="error-text">
                        {errors.name.message}
                      </small>
                    )}
                  </div>
                  <div className="text-field-error">
                    <TextField
                      name="email"
                      id="email"
                      type="email"
                      label="Email Address *"
                      placeholder="Email Address *"
                      autoComplete="off"
                      className={`contact-form-textfield msg-field ${
                        errors.email && "invalid"
                      }`}
                      {...register("email", {
                        required: "Email is Required !",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address !",
                        },
                      })}
                      onChange={(e) => {
                        clearInputError("email");
                        setEmail(e.target.value);
                      }}
                    />
                    {errors.email && (
                      <small className="error-text">
                        {errors.email.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="text-field-first">
                  <div className="text-field-error">
                    <TextField
                      name="phone"
                      id="phone"
                      type="tel"
                      label="Phone Number *"
                      placeholder="Phone Number *"
                      className={`contact-form-textfield msg-field ${
                        errors.phone && "invalid"
                      }`}
                      {...register("phone", {
                        required: "Phone is Required !",
                        pattern: {
                          value:
                            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          message: "Invalid phone no !",
                        },
                      })}
                      onChange={(e) => {
                        clearInputError("phone");
                        setPhone(e.target.value);
                      }}
                    />
                    {errors.phone && (
                      <small className="error-text">
                        {errors.phone.message}
                      </small>
                    )}
                  </div>
                  <div className="text-field-error">
                    <TextField
                      name="address"
                      id="address"
                      type="text"
                      label="Address"
                      placeholder="Address"
                      className={`contact-form-textfield msg-field ${
                        errors.address && "invalid"
                      }`}
                      {...register("address", {
                        required: "address is Required !",
                      })}
                      onChange={(e) => {
                        clearInputError("address");
                        setAddress(e.target.value);
                      }}
                    />
                    {errors.address && (
                      <small className="error-text">
                        {errors.address.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="text-field-error">
                  <TextField
                    name="message"
                    id="message"
                    type="text"
                    label="Message"
                    placeholder="Message"
                    multiline
                    rows={4}
                    className={`contact-form-textarea msg-field ${
                      errors.message && "invalid"
                    }`}
                    {...register("message", {
                      required: "Message is Required !",
                    })}
                    onChange={(e) => {
                      clearInputError("message");
                      setMessage(e.target.value);
                    }}
                  />
                  {errors.message && (
                    <small className="error-text">
                      {errors.message.message}
                    </small>
                  )}
                </div>
                {success && (
                  <Alert severity="success">Message sent successfully!</Alert>
                )}
                <button
                  type="submit"
                  className="contact-form-button"
                  variant="contained"
                >
                  SEND A MESSAGE
                </button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactComponent;

// import React from "react";
// import { Col, Row, Container, Image, Button } from "react-bootstrap";
// import Alert from '@mui/material/Alert';
// import ContactImage from "./../../assets/contact-img.svg";
// import TextField from "@mui/material/TextField";
// import "./contact.css";

// const ContactComponent = () => {
//   const [result, setResult] = React.useState("");
//   const [showSuccessPopup, setShowSuccessPopup] = React.useState(false);
//   const formRef = React.useRef(null);

//   const onSubmit = async (event) => {
//     event.preventDefault();
//     setResult("Sending...");

//     const formData = new FormData(event.target);
//     formData.append("access_key", "4be3d1c8-63e3-4dd4-9625-8b54ac9e3e1c");

//     const res = await fetch("https://api.web3forms.com/submit", {
//       method: "POST",
//       body: formData,
//     }).then((res) => res.json());

//     if (res.success) {
//       console.log("Success", res);
//       setResult(res.message);
//       setShowSuccessPopup(true);
//       formRef.current.reset();
//     } else {
//       console.log("Error", res);
//       setResult(res.message);
//       setShowSuccessPopup(false);
//     }
//   };

//   return (
//     <section id="contactus" data-section>
//       <Container className="contact-main-container">
//         <Row>
//           <Col lg={5} className="contact-img-container">
//             <Image src={ContactImage} />
//             <div className="text-center contact-p">
//               <span>
//                 For further inquiries, please don't hesitate to get in touch
//                 with us.
//               </span>
//             </div>
//           </Col>

//           <Col lg={7} className="contact-container">
//             <div className="contact-head">
//               <span>Get In Touch With Our Experts!</span>
//               <h1>Book a Free Consultation</h1>
//             </div>
//             <div>
//               <form onSubmit={onSubmit} className="contact-form" ref={formRef}>
//                 <div className="text-field-first">
//                   <TextField
//                     name="name"
//                     id="name"
//                     label="Your Name*"
//                     className="contact-form-textfield"

//                   />
//                   <TextField
//                     name="email"
//                     id="email"
//                     label="Email Address *"
//                     className="contact-form-textfield"

//                   />
//                 </div>
//                 <div className="text-field-first">
//                   <TextField
//                     name="phone"
//                     id="phone"
//                     label="Phone Number *"
//                     className="contact-form-textfield"

//                   />
//                   <TextField
//                     name="address"
//                     id="address"
//                     label="Address"
//                     className="contact-form-textfield"
//                   />
//                 </div>
//                 <TextField
//                   name="message"
//                   id="message"
//                   label="Message"
//                   multiline
//                   rows={4}
//                   className="contact-form-textarea"
//                 />
//                 <button
//                   type="submit"
//                   className="contact-form-button"
//                   variant="contained"
//                 >
//                   SEND A MESSAGE
//                 </button>

//               </form>
//             </div>
//           </Col>
//         </Row>
//       </Container>

//       {showSuccessPopup && (
//         <div style={{ margin: "20px" }}>
//           <Alert severity="success" onClose={() => setShowSuccessPopup(false)}>
//             Your message has been sent successfully!
//           </Alert>
//         </div>
//       )}
//     </section>
//   );
// };

// export default ContactComponent;
