

import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GalleryModal from "./gallery-modal";
import "./gallery.css";
import axios from "./../network/api";
import { baseUrl } from "../network/constants/Constants";

const GalleryComponent = () => {
  const [visibleImages, setVisibleImages] = useState(8);
  const [showModal, setShowModal] = useState(false);
  const [clickedImageIndex, setClickedImageIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryLoading, setGalleryLoading] = useState("");


  useEffect(() => {
    fetchGallery();
}, []);


  const fetchGallery = () => {
    setGalleryLoading(true);
    axios
        .get(baseUrl + "web/photo-gallery/")
        .then((response) => {
            setGalleryLoading(false);
            setGalleryImages(response.data);

            galleryImages.forEach(element => {
                console.log("Image ID: " + element.id);
                console.log("Image Link: " + element.file);
            });


        })
        .catch((error) => {
            console.log("Error" + error);
            setGalleryLoading(false);
        });
};




  const handleViewMore = () => {
    setVisibleImages((prevVisibleImages) => prevVisibleImages + 4);
  };

  const handleImageClick = (index) => {
    setClickedImageIndex(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <section id="gallery" data-section>
      <>
      <Container >
        <div className="home-gallery mb-4">
          <h1 className="home-project-title">Gallery</h1>
          {galleryImages.length === 0 ? (
            <p>No images available to display.</p>
          ) : (
            <Row className="g-4">
              {galleryImages.slice(0, visibleImages).map((item, index) => (
                <Col md={3} className="gallery-item" key={item.id}>
                  
                  <img
                    src={item.file}
                    alt="Gallery"
                    className="gallery-images img-fluid"
                    onClick={() => handleImageClick(index)}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
        </Container>

        {galleryImages.length > visibleImages && (
          <Container fluid className="main-bnr">
            <div className="view-more-banner">
              <Container>
                <Row>
                  <Col md={8} className="view-more-banner-p">
                    <p>You Dream it, We Make it</p>
                  </Col>
                  <Col md={4} className="view-button-container">
                    <button
                      onClick={handleViewMore}
                      className="view-more-button"
                    >
                      VIEW MORE
                    </button>
                  </Col>
                </Row>
              </Container>
            </div>
            </Container>

        )}

        {showModal && (
          <GalleryModal
            galleryImages={galleryImages}
            show={showModal}
            handleCloseModal={handleCloseModal}
            initialIndex={clickedImageIndex}
          />
        )}
      </>
    </section>
  );
};

export default GalleryComponent;


