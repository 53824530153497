// OneProjectPage.js
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import EachProjects from "../components/each_project/each_project";
import GalleryModal from "./gallery";
import BookBanner from "../components/book_banner/book_banner";

const OneProjectPage = () => {
  const location = useLocation();
  const projectData = location.state;
  const [visibleImages, setVisibleImages] = useState(8);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); 

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleImageClick = (index) => { 
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const hasImages = projectData && projectData.project_images && projectData.project_images.length > 0;

  return (
    <section id="projects" data-section>
      <EachProjects   />
      <Container>
        <div className="home-gallery mb-4">
          <Row className="g-4">
            {hasImages ? (
              projectData.project_images.slice(0, visibleImages).map((item, index) => (
                <Col md={3} className="gallery-item" key={index}>
                  <img
                    src={item}
                    alt="Gallery"
                    className="gallery-images img-fluid"
                    onClick={() => handleImageClick(index)}
                  />
                </Col>
              ))
            ) : (
              <Col>
                <p>No images to display</p>
              </Col>
            )}
          </Row>
        </div>
      </Container>
      {showModal && (
        <GalleryModal
          galleryImages={projectData.project_images}
          initialIndex={selectedImageIndex} 
          show={showModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      <BookBanner/>
    </section>
  );
};

export default OneProjectPage;
