import Carousel from "react-bootstrap/Carousel";
import BannerImage from "./../../assets/Banner.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../network/constants/Constants";
import "./banner.css";

const BannerComponent = () => {
  const [loading, setLoading] = useState(false);
  const [BannerImage, setBannerImage] = useState([]);

  const fetchBanner = () => {
    setLoading(true);
    axios
      .get(baseUrl + "web/banners/")
      .then((response) => {
        setLoading(false);
        if (response.data && Array.isArray(response.data)) {
          setBannerImage(response.data);
        } else {
          console.error("Unexpected data format:", response.data);
          setBannerImage([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Banner image:", error);
      });
  };

  useEffect(() => {
    fetchBanner();
  }, []);
  return (
    <>
      <section id="home">
        <Carousel transitiontime={1500}>
        {BannerImage.map((item, index) => (
          <Carousel.Item interval={1500} key={index}>
            <img className="banner-img w-100" src={item.banner_image} alt="Banner" />
            <Carousel.Caption className="banner-text"></Carousel.Caption>
          </Carousel.Item>
           ))}
        </Carousel>
      </section>
    </>
  );
};
export default BannerComponent;

// import React from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
// import BannerImage from "./../../assets/Banner.png";

// const BannerComponent = () => {
//   return (
//     <>
//       <section id="home">
//         <Carousel interval="1000" transitionTime="1000" showThumbs={false}>
//           <div>
//             <img src={BannerImage} alt="first" />
//             <p>Legend 1</p>
//           </div>
//           <div>
//             <img src={BannerImage} alt="second" />
//             <p>Legend 2</p>
//           </div>
//           <div>
//             <img src={BannerImage} alt="third" />
//             <p>Legend 3</p>
//           </div>
//         </Carousel>
//       </section>
//     </>
//   );
// };
// export default BannerComponent;

// import React from "react";
// import "./banner.css";
// import { Container } from "react-bootstrap";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

// const BannerComponent = () => {
//   return (
//     <>
//     <section id="home">
//     <div className="banner-container">
//       <div className="banner">
//         <Container>
//         <div className="banner-content">
//           <h1 className="banner-heading">Build Your Dream Home</h1>
//           <p>
//             The construction of the dream home is a <br></br> milestone in most
//             of our lives. We are here to<br></br> partner in your endeavor to
//             realize this dream<br></br>
//             and effectively implement construction with the <br></br> best
//             professional civil engineering standards<br></br> in the industry.
//           </p>
//           <section id="services" data-section>
//           <a className="banner-link" href="#contactus">
//             BOOK CONSULTATION
//           </a>
//           </section>
//         </div>
//         </Container>
//       </div>
//     </div>
//     </section>
//      <section className="services-container">
//      <Container>
//      {/* <h2 className="service-heading">Our services</h2> */}
//        <Row>
//          <Col className="d-flex justify-content-center  services ">
//            <div className="support-elements">
//              <div className="support-card " >
//                <span>Building Construction</span>
//                <p className="support-text">
//                  We are the one of the leading Contractor for industrial
//                  buildings and provide high-quality and excellent services.
//                </p>
//              </div>
//              <div className="support-card ">
//                <span>Interior Designing</span>
//                <p className="support-text">
//                  From filling out applications to preparing documents, we'll
//                  ensure a smooth application process.
//                </p>
//              </div>
//              <div className="support-card ">
//                <span>Building Planning</span>
//                <p className="support-text">
//                  Our experts will provide the support and advice you need to
//                  secure the necessary visas and permits.
//                </p>
//              </div>
//            </div>
//          </Col>
//        </Row>
//      </Container>
//    </section>
//    </>
//   );
// };
// export default BannerComponent;
