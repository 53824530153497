import React from "react";
import { Container } from "react-bootstrap";
import "./about.css";

const AboutComponent = () => {
  return (
    <section>
      <Container>
        <h1 className="about-heading">About Us</h1>
      </Container>
      <div className="about-container">
        <Container className="about-para-div">
          <p className="about-content">
            For over 13 years, our unwavering commitment to excellence has been
            at the forefront of our construction endeavors, dedicated to
            crafting superior house plans and designs for plot owners in
            Alappuzha. Throughout this remarkable journey, we have not only
            honed our expertise but also discovered the unique charm of catering
            to customers beyond the borders of Kerala. Even when working with
            clients who hail from regions outside Kerala, our versatility
            shines. Embracing this diversity, we offer a canvas for innovative
            ideas that transcend geographical boundaries. This adaptability
            allows us to seamlessly blend client-specific requirements with the
            core principles of our builders' concept. In doing so, we not only
            meet the distinctive needs of each individual but also stay true to
            the essence of our construction philosophy. Our approach goes beyond
            constructing mere structures; it is about creating homes that
            resonate with the aspirations and preferences of our clients. This
            commitment to personalized service, combined with years of
            experience, positions us as a trusted partner in the realization of
            dreams and the construction of homes that stand as testaments to
            quality, creativity, and enduring design. As we look towards the
            future, we remain dedicated to pushing the boundaries of
            construction innovation while maintaining the integrity that has
            defined our legacy for over a decade.
          </p>
        </Container>
      </div>
    </section>
  );
};
export default AboutComponent;
