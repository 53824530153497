import React from 'react'
import AllProjects from '../components/project-container/all_project';
import BookBanner from '../components/book_banner/book_banner';

const ProjectPage = () => {
  return (
   <>
   <AllProjects/>
   <BookBanner/>
   </>
  )
}
export default ProjectPage;
