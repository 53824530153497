
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const GalleryModal = ({ galleryImages, show, handleCloseModal, initialIndex }) => {

  const [currentIndex, setCurrentIndex] = useState(initialIndex);


  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };


  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg" className="transparent-modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-image-container">

          <Button variant="light" onClick={handlePrevious} className="arrow-btn">
            <FaArrowLeft />
          </Button>
          <img
            src={galleryImages[currentIndex]} 
            alt="Gallery"
            className="modal-image img-fluid"
          />
     
          <Button variant="light" onClick={handleNext} className="arrow-btn">
            <FaArrowRight />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GalleryModal;
