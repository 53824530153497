import ViewMoreBanner from "../view_more_banner/view_more_banner";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "./../network/api";
import { baseUrl } from "../network/constants/Constants";
// import projects from "./project-details";
import "./project-container.css";



const ProjectContainer = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [project, setProjects] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProject();
  }, []);

  const fetchProject = () => {
    setLoading(true);
  
    axios
      .get(baseUrl + "web/projects/")
      .then((response) => {
        // console.log("API response data:", response.data);
        setLoading(false);

       if (response.data && Array.isArray(response.data)) {
            setProjects(response.data);
        } else {
            console.error("Unexpected data format:", response.data);
            setProjects([]);
        }

      })
      .catch((error) => {
        setLoading(false); 
        console.error("Error fetching blog data:", error);
      });
  };


  function handleProjectNavigation(id) {
    const projectItem = project.find((item) => item.id === id);
    navigate(`/projectpage/${id}`, { state: projectItem });
  }

  return (
    <>
      <section id="projects" data-section className="project-container">
        <Container>
          <h2 className="project-heading">Our Projects</h2>
          {project.length > 0 ? (
          <div className="projects-list">
            {project.slice(0, 4).map((projectItem, index) => (
              <div
                className="project-card"
                key={projectItem.id}  
                onClick={() => handleProjectNavigation(projectItem.id)}
                eventkey={index.toString()}
              >
                <div className="project-item-background">
                <div className="prj-img-container">
                    <img src={projectItem.thumb} alt={projectItem.name} />
                  </div>
                  <div className="project-details">
                    <p className="project-name" dangerouslySetInnerHTML={{
                        __html:
                          projectItem.project_name.length > 20
                            ? projectItem.project_name.slice(0, 5) + "..."
                            :projectItem.project_name,
                      }}/>
                    {/* <p className="support-text">{projectItem.description}</p> */}
                    <p className="support-text"  dangerouslySetInnerHTML={{
                        __html:
                          projectItem.description.length > 250
                            ? projectItem.description.slice(0, 60) + "..."
                            :projectItem.description,
                      }}/>
                    
                    <Link
                     onClick={() => handleProjectNavigation(projectItem.id)}
                      style={{ textDecoration: "none" }}
                    >
                      MORE
                    </Link>
                  </div>
                  <div className="green-box"></div>
                </div>
              </div>
            ))}
          </div>
            ) : (
          <p>No projects to display.</p>
      )}
        </Container>
      </section>
      <ViewMoreBanner />
    </>
  );
};

export default ProjectContainer;
