import React from "react";
import BannerComponent from "../components/banner/banner";
import ServiceComponent from "../components/services/services";
import ProjectContainer from "../components/project-container/project-container";
import AboutComponent from "../components/about/about";
import SocialMediaComponent from "../components/social-media/social-media";
import GalleryComponent from "../components/gallery/gallery";
import Testimonials from "../components/testimonials/testimonials";
import ContactComponent from "../components/contact/contact";

const Home = () => {
  return (
    <>
      <BannerComponent />
      <ServiceComponent />
      <ProjectContainer />
      <AboutComponent />
      <SocialMediaComponent />
      <GalleryComponent />
      <Testimonials />
      <ContactComponent />
    </>
  );
};
export default Home;
