import React, { useState, useEffect, useRef } from "react";
import { Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import Navbar from "react-bootstrap/Navbar";
import Brand from "./../../assets/EkoHomezBrand.svg";
import PhoneIcon from "./../../assets/ring.png";
import { HashLink } from "react-router-hash-link";

const NavbarComponent = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef([]);

  const stickNavbar = () => {
    if (window.scrollY > 100) {
      setStickyClass("sticky-top");
    } else {
      setStickyClass("");
    }
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    let newActiveSection = "";

    sectionsRef.current.forEach((section) => {
      const { offsetTop, offsetHeight } = section;
      if (scrollY >= offsetTop && scrollY < offsetTop + offsetHeight) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  useEffect(() => {
    sectionsRef.current = Array.from(
      document.querySelectorAll("[data-section]")
    );
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    
    <div className={`navbar-content ${stickyClass}`}>
      <Navbar expand="lg" collapseOnSelect className="navbar-container ">
        <Container>
          <Navbar.Brand href="/#home">
            <div className="brand-logo">
              <img src={Brand} alt="Brand Logo" className="logo-img" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="">
              <Nav.Link
                   as={HashLink}
                   href="/#home"
                   to="/#home"
                   scroll={(el) => {
                     const yCoordinate =
                       el.getBoundingClientRect().top + window.scrollY;
                     const yOffset = -400;
                     window.scrollTo({
                       top: yCoordinate + yOffset,
                       behavior: "smooth",
                     });
                   }}
                className={activeSection === "home" ? "active" : ""}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={HashLink}
                href="/#services"
                to="/#services"
                scroll={(el) => {
                  const yCoordinate =
                    el.getBoundingClientRect().top + window.scrollY;
                  const yOffset = -400;
                  window.scrollTo({
                    top: yCoordinate + yOffset,
                    behavior: "smooth",
                  });
                }}
                className={activeSection === "services" ? "active" : ""}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/#projects"
                className={activeSection === "projects" ? "active" : ""}
              >
                Our Projects
              </Nav.Link>
              <Nav.Link
                href="/#gallery"
                className={activeSection === "gallery" ? "active" : ""}
              >
                Our Gallery
              </Nav.Link>
              <Nav.Link
                href="/contactus"
                className={activeSection === "contactus" ? "active" : ""}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="contact-nav">
            <img src={PhoneIcon} alt="Phone Icon" className="phone" />
            <a
              href="tel:+91 95445 06205"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="contact-content">
                <h6>Call Anytime</h6>
                <h5>+91 95445 06205</h5>
              </div>
            </a>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;

// import React, { useState, useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
// import { Container, Nav } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./navbar.css";
// import Navbar from "react-bootstrap/Navbar";
// import Brand from "./../../assets/EkoHomezBrand.svg";
// import PhoneIcon from "./../../assets/ring.png";

// const NavbarComponent = () => {
//   const { hash } = useLocation();
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//   // Scroll to the target section based on hash in the URL
//   useEffect(() => {
//     if (hash === "") {
//       window.scrollTo(0, 0);
//     } else {
//       setTimeout(() => {
//         const id = hash.replace("#", "");
//         const element = document.getElementById(id);
//         if (element) {
//           setTimeout(() => {
//             element.scrollIntoView({
//               behavior: "smooth",
//               block: "start",
//             });
//           }, 100);
//         }
//       }, 100);
//     }
//   }, [hash]);

//   // Add a scroll event listener to highlight the current section in the navbar
//   useEffect(() => {
//     function navHighlighter() {
//       const sections = document.querySelectorAll("section[id]");
//       let scrollY = window.scrollY; // Use window.scrollY instead of window.pageYOffset

//       let homeLink = document.querySelector(".navbar-nav a[href*=homepage]");
//       homeLink.classList.remove("active");

//       sections.forEach((current) => {
//         const sectionHeight = current.offsetHeight;
//         const sectionTop = current.offsetTop - 70;
//         let sectionId = current.getAttribute("id");

//         if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
//           if (sectionId === "header") {
//             homeLink.classList.add("active");
//           } else {
//             const sectionLink = document.querySelector(
//               `.navbar-nav a[href*=${sectionId}]`
//             );
//             if (sectionLink) {
//               sectionLink.classList.add("active");
//             }
//           }
//         } else {
//           const sectionLink = document.querySelector(
//             `.navbar-nav a[href*=${sectionId}]`
//           );
//           if (sectionLink) {
//             sectionLink.classList.remove("active");
//           }
//         }
//       });
//     }

//     window.addEventListener("scroll", navHighlighter);

//     return () => {
//       window.removeEventListener("scroll", navHighlighter);
//     };
//   }, []);

//   return (
//     <section id="homepage">
//       <Navbar expand="lg" collapseOnSelect className="navbar-container ">
//         <Container>
//           <Navbar.Brand href="/">
//             <div className="brand-logo">
//               <img src={Brand} alt="Brand Logo" className="logo-img" />
//             </div>
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsNavbarOpen(!isNavbarOpen)} />
//           <Navbar.Collapse
//             id="basic-navbar-nav"
//             in={isNavbarOpen}
//             className="justify-content-center"
//           >
//             <Nav className="">
//               <Nav.Link
//                 href="/#homepage"

//                 onClick={() => setIsNavbarOpen(false)}
//               >
//                 Home
//               </Nav.Link>
//               <Nav.Link
//                 href="/#services"

//                 onClick={() => setIsNavbarOpen(false)}
//               >
//                 Our Services
//               </Nav.Link>
//               <Nav.Link
//                 href="/#gallery"

//                 onClick={() => setIsNavbarOpen(false)}
//               >
//                 Our Gallery
//               </Nav.Link>
//               <Nav.Link
//                 href="/#projects"

//                 onClick={() => setIsNavbarOpen(false)}
//               >
//                 Our Projects
//               </Nav.Link>
//               <Nav.Link
//                 href="/#contactus"

//                 onClick={() => setIsNavbarOpen(false)}
//               >
//                 Contact Us
//               </Nav.Link>
//             </Nav>
//           </Navbar.Collapse>

//           <div className="contact-nav">
//             <img src={PhoneIcon} alt="Phone Icon" className="phone" />
//             <div className="contact-content">
//               <h6>Call Anytime</h6>
//               <h5>+91 97441 16907</h5>
//             </div>
//           </div>
//         </Container>
//       </Navbar>
//     </div>
//     </section>
//   );
// };

// export default NavbarComponent;
